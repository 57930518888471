<template>
  <div class="practice-rank">
    <div class="top_content">
      <!-- <div class="left_icon">
        <select @change="changeData" v-model="year">
          <option v-for="(item, index) in years" :key="index" :label="item + '年'" :value="item"></option>
        </select>
      </div> -->
      
      <!-- <arrow-title :title="title" titleStyle="width: 200px"></arrow-title> -->
      <!-- <div class="right_icon" @click="hideNum()">
        <img src="../assets/png_11.png" />
      </div> -->
    </div>
    <div class="middle">
      <div class="inner_middle">
        <table border="0" v-if="!ifShow">
          <thead>
            <tr>
              <td width="40"></td>
              <td width="120">服务领域</td>
              <td width="90">
                <p>计划总数</p>
              </td>
              <td width="70">已完成数</td>
              <td width="70">百分比</td>
              <td width="90">
                <p>新增已完成</p>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in list" :key="index">
              <td>
                <span v-if="index == 0"><img src="../assets/png_12.png" /></span>
                <span v-else-if="index == 1"><img src="../assets/png_13.png" /></span>
                <span v-else-if="index == 2"><img src="../assets/png_14.png" /></span>
                <span class="span" v-else>{{ index + 1 }}</span>
              </td>
              <td @click="showNextEmit(item, 0)" class="pointer">
                {{ item.name ? item.name.substring(0, 4) : "" }}
              </td>
              <td>{{ item.allcount }}</td>
              <td>{{ item.over }}</td>
              <!-- <td>{{ item.percen }}</td> -->
              <td>
                {{
                  isNaN(item.over / item.allcount)
                    ? "0.0%"
                    : ((item.over / item.allcount) * 100).toFixed(1) + "%"
                }}

              </td>
              <td>{{ item.monthover }}</td>
            </tr>
          </tbody>
        </table>
        <table border="0" v-if="ifShow">
          <thead>
            <tr>
              <td width="30"></td>
              <td width="180">实践点名称</td>
              <td width="50">已完成数</td>
              <td width="50">
                <p>本月新增</p>
                <p>已完成</p>
              </td>
            </tr>
          </thead>
          <tbody>
            <template v-if="ifShow">
              <tr v-for="(item, index) in innerList" :key="index">
                <td>
                  <span v-if="index == 0"><img src="../assets/png_12.png" /></span>
                  <span v-else-if="index == 1"><img src="../assets/png_13.png" /></span>
                  <span v-else-if="index == 2"><img src="../assets/png_14.png" /></span>
                  <span class="span" v-else>{{ index + 1 }}</span>
                </td>
                <td @click="showInnerEmit(item, 1)" class="pointer">
                  {{ item.name ? item.name : ""
                  }}<span v-if="item.level == 2">(本级)</span>
                </td>
                <td>{{ item.over }}</td>
                <td>{{ item.monthover }}</td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>
    <div class="bottom"></div>
  </div>
</template>

<script>
import { getPlanRankApi, getPlanNextRankApi } from "../api";
export default {
  name: "ShowNumber",
  data() {
    return {
      title: "实践站年度计划排行榜",
      ifShow: false,
      list: [],
      innerList: [],
      numArr: [],
      itemInfo: "",
      year: "2024",
      years: ["2020", "2021", "2022", "2023", '2024'],
      posionItem: {},
    };
  },
  methods: {
    init() {
      let form = new URLSearchParams();
      form.append("year", this.year);
      getPlanRankApi(form)
        .then((res) => {
          res = res.data;
          if (res.code == 200) {
            this.list = res.data;
            this.list.map((item, index) => {
              if (item.name.indexOf("东山社区") != -1) {
                item.allcount = 250;
              } else if (item.name.indexOf("泥塘社区") != -1) {
                item.allcount = 190;
              } else if (item.name.indexOf("晓里社区") != -1) {
                item.allcount = 150;
              } else if (item.name.indexOf("龙西社区") != -1) {
                item.allcount = 105;
              } else if (item.name.indexOf("中前社区") != -1) {
                item.allcount = 100;
              } else if (item.name.indexOf("佘村社区") != -1) {
                item.allcount = 100;
              } else if (item.name.indexOf("永安社区") != -1) {
                item.allcount = 80;
              } else if (item.name.indexOf("大里社区") != -1) {
                item.allcount = 80;
              } else if (item.name.indexOf("骆村社区") != -1) {
                item.allcount = 150;
              } else if (item.name.indexOf("邵圣社区") != -1) {
                item.allcount = 85;
              } else if (item.name.indexOf("岔路社区") != -1) {
                item.allcount = 145;
              } else if (item.name.indexOf("外港社区") != -1) {
                item.allcount = 85;
              } else if (item.name.indexOf("上坊社区") != -1) {
                item.allcount = 135;
              } else if (item.name.indexOf("高桥社区") != -1) {
                item.allcount = 90;
              } else if (item.name.indexOf("天云社区") != -1) {
                item.allcount = 85;
              }
            });
          } else {
            alert(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    hideNum() {
      this.$emit("changeHide");
    },
    async showNext(item) {
      if (!this.ifShow) {
        this.itemInfo = item;
        this.title = item.name;
        let form = new URLSearchParams();
        form.append("area5", item.id);
        form.append("year", this.year);
        let res1 = await getPlanNextRankApi(form);
        this.innerList = res1.data.data;
      } else {
        this.title = "实践站年度计划排行榜";
      }
    },
    showNextEmit(item, tab) {
      console.log(item);
      this.posionItem = item;
      this.showNext(item);
      this.$emit("changeMap");
      this.showInnerEmit(item, tab);
    },
    showInnerEmit(item, tab) {
      if (tab == 0) {
        let result = item;
        result.name = result.name
          ? result.name.substring(0, 4) + "新时代文明实践站"
          : "";
        this.itemInfo = result;
      } else {
        this.itemInfo = item;
      }
      this.$emit("showInnerEmit");
    },
    async changeData() {
      console.log(this.ifShow);
      if (this.ifShow) {
        this.itemInfo = this.posionItem;
        this.title = this.posionItem.name;
        let form = new URLSearchParams();
        form.append("area5", this.posionItem.id);
        form.append("year", this.year);
        let res1 = await getPlanNextRankApi(form);
        console.log(res1);
        this.innerList = res1.data.data;
      } else {
        this.init();
      }
      // if (this.year == 2020) {
      //   this.list = this.list2;
      // } else {
      //   this.list = this.list1;
      // }
    },
  },
  mounted() {
    this.init();
    // this.changeData();
    this.timer = setInterval(this.init, 30 * 60 * 1000);
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>

<style scoped>
* {
  box-sizing: border-box;
}

.pointer {
  cursor: pointer;
}

.practice-rank {
  width: 100%;
  height: 244px;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
  margin-bottom: 10px;
  background-color: #021347;
  box-shadow: 0px 0px 11px 1px rgba(48, 93, 255, 0.35);
  /* opacity: 0.8; */
  border: 1px solid #13376c;
}

.top_content {
  width: 100%;
  height: 35px;
  background-size: 100% 40px;
  background-image: url("../assets/Group 536@2x.png");
  position: relative;
}

.top_content .right_icon {
  width: 15px;
  height: 15px;
  position: absolute;
  right: 20px;
  top: 10px;
  cursor: pointer;
}

.top_content .right_icon img {
  width: 100%;
  height: 100%;
  display: block;
}

.middle {
  width: 100%;
  height: 400px;
  background-size: 100%;
  background-image: url(../assets/png_9.png);
  flex: 1;
  overflow: hidden;
}

.middle .inner_middle {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding-right: 20px;
  margin-top: 10px;
}

.bottom {
  width: 100%;
  height: 10px;
  background-size: 100% 100%;
  background-image: url(../assets/png_10.png);
}

table {
  width: 100%;
  font-size: 10px;
  color: #fff;
  text-align: center;
  background-color: #021347;
}

table thead td {
  height: 16px;
  font-weight: bold;
  font-size: 16px;
}

table img {
  width: 24px;
  height: 24px;
  display: inline-block;
  margin: auto;
}

table tbody td {
  padding: 1px 0;
  font-size: 16px;
}

table .span {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #20a3ff;
  line-height: 15px;
  text-align: center;
  font-size: 10px;
  color: #fff;
  display: block;
  margin: auto;
}

.left_icon {
  width: 50px;
  height: 20px;
  position: absolute;
  right: 20px;
  top: 2px;
  cursor: pointer;
}

select:focus {
  outline: none;
}

.left_icon select {
  background-color: transparent;
  border: none;
  color: #31abe3;
  font-size: 14px;
  text-align: center;
  padding: 0 1%;
  text-align-last: center;
  outline: none;
}

.left_icon option {
  text-align: center;
}

.practice-rank p {
  margin-bottom: 0;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #001336;
}

::-webkit-scrollbar {
  width: 10px;
  background-color: #001336;
}

::-webkit-scrollbar-thumb {
  background-color: #3a5d9c;
  border-radius: 12px;
}

::-webkit-scrollbar {
  height: 4px !important;
  /*滚动条宽度*/
}
</style>
